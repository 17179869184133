export default ({ options, state }) => ({
    show: false,
    state,
    options,

    addTag(tagToAdd) {
        if (tagToAdd === '') {
            return
        }

        if (this.state.includes(tagToAdd)) {
            return
        }

        this.state.push(tagToAdd)
    },

    deleteTag(tagToDelete) {
        this.state = this.state.filter((tag) => tag !== tagToDelete)
    },

    getLabel(tag) {
        this.state = this.state.filter((tag) => tag !== tagToDelete)
    },

    get haveOtherOptions() {
        return this.state.length !== this.options.length
    },
})
