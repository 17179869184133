export default ({ labels }) => ({
    open: false,
    weaponType: null,
    labels,

    getLabel(weaponType) {
        return this.labels[weaponType]
    },

    toggle() {
        this.open = !this.open
    },

    get weaponLabel() {
        return this.getLabel(this.weaponType)
    },
})
