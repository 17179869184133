export default () => ({
    open: false,
    roleName: null,
    icons: {
        controller: 'https://valhub.gg/valhub/agents/roles/controller.png',
        duelist: 'https://valhub.gg/valhub/agents/roles/duelist.png',
        initiator: 'https://valhub.gg/valhub/agents/roles/initiator.png',
        sentinel: 'https://valhub.gg/valhub/agents/roles/sentinel.png',
    },

    getImage(roleName) {
        return this.icons[roleName]
    },

    toggle() {
        this.open = !this.open
    },

    get roleAlt() {
        return this.roleName + '-agent-role-icon'
    },

    get roleSrc() {
        return this.getImage(this.roleName)
    },
})
