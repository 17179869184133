export default (seasons) => ({
    open: false,
    season: null,
    seasons: seasons,

    getLabel(season) {
        return this.seasons[season]
    },

    get seasonLabel() {
        return this.getLabel(this.season)
    },

    toggle() {
        this.open = !this.open
    },
})
