export default ({ agentName, icons }) => ({
    open: false,
    agentName,
    icons,

    getImage(agentName) {
        return this.icons[agentName]
    },

    toggle() {
        this.open = !this.open
    },

    get agentAlt() {
        return agentName + '-agent-icon'
    },

    get agentImg() {
        return this.getImage(this.agentName)
    },
})
