import './bootstrap'

import {
    Livewire,
    Alpine,
} from '../../vendor/livewire/livewire/dist/livewire.esm'

import Clipboard from '@ryangjchandler/alpine-clipboard'
import Tooltip from '@ryangjchandler/alpine-tooltip'
import flatpickr from 'flatpickr'
import tagsInput from './components/tags-input'
import agentRoleSelect from './components/select/agent-role'
import agentSelect from './components/select/agent'
import weaponTypeSelect from './components/select/weapon-type'
import seasonSelect from './components/select/season'
import 'vanilla-picker'
import moment from 'moment-timezone'
import it from 'moment/locale/it'
import Italian from 'flatpickr/dist/l10n/it.js'
import { Splide } from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import AlpineFloatingUI from '@awcodes/alpine-floating-ui'
import.meta.glob(['../img/**', '../images/**', '../fonts/**', '../svg/**'])
import * as Sentry from '@sentry/browser'
import 'brackets-viewer/dist/brackets-viewer.min.js'

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [/^https:\/\/valhub\.gg\/.*/],

    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

moment.locale('it')
window.moment = moment
window.Alpine = Alpine
window.Splide = Splide
window.AutoScroll = AutoScroll
flatpickr.localize(Italian.default.it)

Alpine.plugin(Clipboard)
Alpine.plugin(Tooltip)
Alpine.plugin(AlpineFloatingUI)

Alpine.data('tagsInput', tagsInput)
Alpine.data('agentRoleSelect', agentRoleSelect)
Alpine.data('agentSelect', agentSelect)
Alpine.data('weaponTypeSelect', weaponTypeSelect)
Alpine.data('seasonSelect', seasonSelect)

Alpine.store('shownGoToTop', false)

Livewire.start()
